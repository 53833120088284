import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Badge } from '@mui/material';
import Cached from '@mui/icons-material/Cached';
import { styled } from '@mui/styles';
import { LoadingIconButton } from '../buttons';
import { useBooleanState, useLastRefreshDate } from '../../hooks';
import DatesFilter from './DatesFilter';
import StatusFilter from './StatusFilter';
import HuntingFarmingFilter from './HuntingFarmingFilter';
import { formatMinWordDate } from '../../helpers/date';
import FingoQuickFilter from './FingoQuickFilter';
import FingoPreOfferFilter from './FingoPreOfferFilter';
import RoundedRowStack from '../layout/RoundedRowStack';
import filtersApplied from '../../helpers/filters';
import LastUpdatedDate from '../text/LastUpdatedDate';
import LightningPaymentAuthorizedFilter from './LightningPaymentAuthorizedFilter';
import ValidSiiCredentialsFilter from './ValidSiiCredentialsFilter';
import { ORDERING_DOCUMENTLIST_TYPE } from '../../constants';
import InvoiceStatusFilter from './InvoiceStatusFilter';
import PendingRestrictionsFilter from './PendingRestrictionsFilter';
import ExecutiveFilter from './ExecutiveFilter';
import CompanyFilter from './CompanyFilter';
import { companyFilter } from '../../apollo/reactive-variables';
import selectedCompanyFilter from './selectedCompanyFilter';
import FilterButton from '../buttons/FilterButton';

const FilterBadge = styled(Badge)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flex: '1 1 0px',
  },
}));

const FilterTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('s')]: {
    fontSize: 11,
  },
}));

const Filters = ({
  type,
  dates,
  setDates,
  financingStatusOptions,
  allCompaniesOptions,
  states,
  setStates,
  setCustomVariables,
  flexEndButtons,
  showPreoffersFilters,
  showQuickFilters,
  showValidSiiCredentialsFilter,
  showLightningFilter,
  showPendingRestrictionsFilter,
  showStatesFilter,
  showDatesFilter,
  showCompanyIssuerFilter,
  showHuntingFarmingFilter,
  showInvoiceStatusFilters,
  defaultFilterDays,
  showRefresh,
  onRefresh,
  loading,
  showExportInvoice,
  exportExcelIconButton,
  buttonsToShow,
  showExecutiveFilter,
}) => {
  const [datesAnchorEl, setDatesAnchorEl] = useState(null);
  const [openStateFilter, setOpenStateFilter] = useState(false);
  const [lastUpdated, refreshDateHandler] = useLastRefreshDate();
  const openDateFilter = ({ currentTarget }) => setDatesAnchorEl(currentTarget);
  const closeDateFilter = () => setDatesAnchorEl(null);
  const [, , setStatesClear, setStatesFull] = useBooleanState(false);
  const numberOfFiltersApplied = useMemo(() => filtersApplied(states));
  const refreshLastUpdated = useCallback(() => {
    onRefresh();
    refreshDateHandler();
  }, [onRefresh, refreshDateHandler]);
  const [selectedCompany, setSelectedCompany] = useState(companyFilter().selectedCompany);
  useEffect(
    () => {
      if (showCompanyIssuerFilter) {
        selectedCompanyFilter(selectedCompany, setCustomVariables, defaultFilterDays);
      }
    },
    [selectedCompany],
  );
  return (
    <RoundedRowStack spacing={2}>
      {showRefresh && (
        <LoadingIconButton
          onClick={refreshLastUpdated}
          sx={{ mr: { xs: '0', md: 'auto' } }}
          loading={loading}
          color="gray"
          id="refresh-documents"
          disableRipple
          disableTouchRipple
          disableElevation
        >
          <Cached />
          <LastUpdatedDate lastUpdated={lastUpdated} />
        </LoadingIconButton>
      )}
      {showValidSiiCredentialsFilter && (
        <ValidSiiCredentialsFilter states={states} setStates={setStates} />
      )}
      {showLightningFilter && (
        <LightningPaymentAuthorizedFilter states={states} setStates={setStates} />
      )}
      {showPendingRestrictionsFilter && (
        <PendingRestrictionsFilter states={states} setStates={setStates} />
      )}
      {showPreoffersFilters && (
        <FingoPreOfferFilter
          states={states}
          setStates={setStates}
          filterType={type === ORDERING_DOCUMENTLIST_TYPE ? 'ORDERING' : 'FACTORING'}
        />
      )}
      {showInvoiceStatusFilters && (
        <InvoiceStatusFilter states={states} setStates={setStates} />
      )}
      {showQuickFilters && (
      <FingoQuickFilter
        states={states}
        setStates={setStates}
        buttonsToShow={buttonsToShow}
      />
      )}
      {showStatesFilter && (
        <>
          <FilterBadge badgeContent={numberOfFiltersApplied} color="primary">
            <FilterButton onClick={() => setOpenStateFilter(true)} id="open-filters">
              <FilterTypography variant="subNavItem">Filtros</FilterTypography>
            </FilterButton>
          </FilterBadge>
          <StatusFilter
            openStateFilter={openStateFilter}
            close={() => setOpenStateFilter(false)}
            states={states}
            financingStatusOptions={financingStatusOptions}
            allCompaniesOptions={allCompaniesOptions}
            setStates={setStates}
            setStatesClear={setStatesClear}
            setStatesFull={setStatesFull}
          />
        </>
      )}
      {showDatesFilter && (
        <>
          <FilterButton isdate="true" onClick={openDateFilter} id="filter-dates">
            <FilterTypography variant="subNavItem">
              {`${formatMinWordDate(dates.dateIssued_Gte)} - ${formatMinWordDate(
                dates.dateIssued_Lte,
              )}`}
            </FilterTypography>
          </FilterButton>
          <DatesFilter
            anchorEl={datesAnchorEl}
            close={closeDateFilter}
            dates={dates}
            setDates={setDates}
            states={states}
            setStates={setStates}
          />
        </>
      )}
      {showCompanyIssuerFilter && (
        <CompanyFilter
          size="small"
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          inputLabel={selectedCompany ? selectedCompany.displayNationalIdentifier : 'Todas - Últimos 14 días'}
          sx={{ width: '280px' }}
        />
      )}
      {showExecutiveFilter && (
        <ExecutiveFilter
          setExecutiveCallback={(
            node,
          ) => setStates((rest) => ({ ...rest,
            companyExecutiveAssignedId: node?.graphqlId || null,
          }))}
        />
      )}
      {showHuntingFarmingFilter && (
        <HuntingFarmingFilter
          setHuntingFarmingCallback={(
            value,
          ) => setStates((rest) => ({ ...rest,
            farmingByCommercialType: value,
          }))}
        />
      )}
      {showExportInvoice && exportExcelIconButton}
      {flexEndButtons()}
    </RoundedRowStack>
  );
};

Filters.propTypes = {
  type: PropTypes.string,
  financingStatusOptions: PropTypes.bool,
  allCompaniesOptions: PropTypes.bool,
  dates: PropTypes.shape({
    dateIssued_Gte: PropTypes.instanceOf(moment),
    dateIssued_Lte: PropTypes.instanceOf(moment),
  }),
  setDates: PropTypes.func,
  setCustomVariables: PropTypes.func.isRequired,
  states: PropTypes.shape({
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    loanedStatus: PropTypes.arrayOf(PropTypes.string),
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  setStates: PropTypes.func,
  flexEndButtons: PropTypes.func,
  showQuickFilters: PropTypes.bool,
  showValidSiiCredentialsFilter: PropTypes.bool,
  showLightningFilter: PropTypes.bool,
  showPendingRestrictionsFilter: PropTypes.bool,
  showStatesFilter: PropTypes.bool,
  showDatesFilter: PropTypes.bool,
  showPreoffersFilters: PropTypes.bool,
  showInvoiceStatusFilters: PropTypes.bool,
  defaultFilterDays: PropTypes.number,
  showCompanyIssuerFilter: PropTypes.bool,
  showHuntingFarmingFilter: PropTypes.bool,
  showRefresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  showExportInvoice: PropTypes.bool,
  exportExcelIconButton: PropTypes.node,
  buttonsToShow: PropTypes.arrayOf(PropTypes.string),
  showExecutiveFilter: PropTypes.bool,
};

Filters.defaultProps = {
  type: '',
  defaultFilterDays: 3,
  dates: null,
  financingStatusOptions: false,
  allCompaniesOptions: false,
  setDates: () => {},
  states: {
    siiStatus: [],
    loanedStatus: [],
    creditNoteStatus: [],
    allCompanies: [],
    documentFinanceStatus: [],
  },
  setStates: () => {},
  flexEndButtons: () => <></>,
  showStatesFilter: true,
  showCompanyIssuerFilter: false,
  showHuntingFarmingFilter: false,
  showDatesFilter: true,
  showQuickFilters: false,
  showValidSiiCredentialsFilter: false,
  showLightningFilter: false,
  showPendingRestrictionsFilter: false,
  showPreoffersFilters: false,
  showInvoiceStatusFilters: false,
  showRefresh: false,
  onRefresh: () => {},
  loading: false,
  showExportInvoice: true,
  exportExcelIconButton: null,
  buttonsToShow: [],
  showExecutiveFilter: false,
};

export default Filters;
