import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { FingoLogo, LinkedinIcon, InstagramIcon } from '../../assets';
import { mapsLink, instagramLink, linkedinLink } from '../../constants/links';

const style = {
  logo: {
    width: 100,
  },
  href: {
    color: '#323232',
    textDecoration: 'none',
  },
  socialMedia: {
    width: '1.2rem',
    marginTop: '25%',
  },
};

function ContactMobileFooter() {
  return (
    <Stack
      direction="column"
      spacing={1.5}
      sx={{
        backgroundColor: '#F8F8F8',
        color: 'text.primary',
        padding: '2rem',
      }}
    >
      <Typography variant="h2">Contáctanos</Typography>
      <Stack direction="column" spacing={1} sx={{ paddingBottom: 1 }}>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">
            Comercial
          </Typography>
          <Typography variant="subtitle2">
            +56 9 6246 4446 / contacto@fingo.cl
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">
            Cobranza
          </Typography>
          <Typography variant="subtitle2">
            +56 9 9434 1225 / cobranza_ratificacion@fingo.cl
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">
            Dirección
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Typography variant="subtitle2">
              Av. Apoquindo 4660,  Piso 8. Las Condes
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={600}
            >
              <a href={mapsLink} style={style.href} rel="noopener noreferrer" target="_blank">
                Ver en Maps
              </a>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1">
            ¡Síguenos!
          </Typography>
          <Box>
            <a href={linkedinLink} rel="noopener noreferrer">
              <img src={LinkedinIcon} alt="logo" style={style.socialMedia} />
            </a>
          </Box>
          <Box>
            <a href={instagramLink} rel="noopener noreferrer">
              <img src={InstagramIcon} alt="logo" style={style.socialMedia} />
            </a>
          </Box>
        </Stack>
        <Box>
          <a href="/" rel="noopener noreferrer">
            <div>
              <img src={FingoLogo} alt="logo" style={style.logo} />
            </div>
          </a>
        </Box>
      </Stack>
    </Stack>
  );
}

export default ContactMobileFooter;
