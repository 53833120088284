import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Grid, CircularProgress, Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { INVOICE_STATUS_MAIL_PREVIEW } from '../../graphql';

const InvoiceStatusMailPreview = ({ userId, invoiceId, emailType }) => {
  const [preview, setPreview] = useState();
  const [getPreview] = useMutation(INVOICE_STATUS_MAIL_PREVIEW, {
    onCompleted: (data) => setPreview(Object.values(data)[0].preview),
  });
  useEffect(() => {
    getPreview({
      variables: {
        userId,
        invoiceId,
        emailType,
      },
    });
  }, []);
  if (!preview) {
    return (
      <Grid container justifyContent="center" padding={2}>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid sx={{ height: '600px' }}>
      <Box sx={{ transformOrigin: 'center',
        transform: 'scale(0.8)',
        mt: '-40px',
        ml: '40px' }}
      >
        {parse(preview)}
      </Box>
    </Grid>
  );
};

InvoiceStatusMailPreview.propTypes = {
  userId: PropTypes.number.isRequired,
  invoiceId: PropTypes.number.isRequired,
  emailType: PropTypes.string.isRequired,
};

export default InvoiceStatusMailPreview;
