import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_SIMPLE_MASTER_ENTITIES } from '../../graphql';
import { useGetCountryFromUrl } from '../../hooks';
import { CompanyType } from '../../propTypes';

const CompanyFilter = ({
  selectedCompany,
  setSelectedCompany,
  companyFilterSetProps,
  inputLabel,
  ...props
}) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const country = useGetCountryFromUrl();
  const { data: companies, loading } = useQuery(GET_SIMPLE_MASTER_ENTITIES, {
    variables: {
      globalFilter,
      first: 10,
      countryId: country?.id,
      ...companyFilterSetProps,
    },
  });
  const options = useMemo(
    () => companies?.getMasterEntities.edges.map((i) => i.node) || [],
    [companies],
  );
  return (
    <Autocomplete
      loading={loading}
      id="select-company"
      options={options}
      getOptionLabel={(option) => `${option?.displayNationalIdentifier} ${option?.name}` || ''}
      onChange={(_, value) => {
        setSelectedCompany(value);
      }}
      value={selectedCompany}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(_options, params) => {
        setGlobalFilter(params.inputValue);
        return _options;
      }}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
      {...props}
    />
  );
};

CompanyFilter.propTypes = {
  setCustomVariables: PropTypes.func.isRequired,
  defaultFilterDays: PropTypes.number,
  selectedCompany: CompanyType,
  setSelectedCompany: PropTypes.func.isRequired,
  companyFilterSetProps: PropTypes.shape(),
  inputLabel: PropTypes.string,
};

CompanyFilter.defaultProps = {
  selectedCompany: null,
  defaultFilterDays: 3,
  inputLabel: 'Selecciona deudor',
  companyFilterSetProps: {},
};

export default CompanyFilter;
