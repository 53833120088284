import React, { useState } from 'react';
import { Menu, Grid, useTheme, Button } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FingoDateRangePicker } from '../datePickers';
import { getFilterByNameFromHistory } from '../../helpers';

const DatesFilter = ({ anchorEl, close, dates, setDates, states, setStates }) => {
  const theme = useTheme();
  const history = useHistory();
  /* We can get the default values from URL search or filters */
  const getDefaultDate = (name) => {
    const urlSearch = moment(getFilterByNameFromHistory(history, name), 'YYYY-MM-DD').toDate();
    const filter = dates[name].toDate();
    // eslint-disable-next-line no-restricted-globals
    return isNaN(urlSearch) ? filter : urlSearch;
  };
  const [datesState, setDatesState] = useState([{
    startDate: getDefaultDate('dateIssued_Gte'),
    endDate: getDefaultDate('dateIssued_Lte'),
    key: 'range',
    color: theme.palette.primary.main,
  }]);
  const eraser = { ...states };
  const save = () => {
    setDates({
      dateIssued_Gte: moment(datesState[0].startDate),
      dateIssued_Lte: moment(datesState[0].endDate),
    });
    setStates({
      ...eraser,
      dateIssued_Gte: moment(datesState[0].startDate).format('YYYY-MM-DD'),
      dateIssued_Lte: moment(datesState[0].endDate).format('YYYY-MM-DD'),
    });
    close();
  };
  const dateChange = ({ range }) => {
    setDatesState([{ ...range }]);
  };
  return (
    <Menu
      id="date-filter"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={close}
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <FingoDateRangePicker
            onChange={dateChange}
            ranges={datesState}
            direction="horizontal"
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" margin="4px">
          <Button
            id="save-date-filter"
            variant="contained"
            color="primary"
            onClick={save}
            size="small"
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Menu>
  );
};

DatesFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  close: PropTypes.func.isRequired,
  dates: PropTypes.shape({
    dateIssued_Gte: PropTypes.instanceOf(moment),
    dateIssued_Lte: PropTypes.instanceOf(moment),
  }).isRequired,
  setDates: PropTypes.func.isRequired,
  setStates: PropTypes.func,
  states: PropTypes.shape({}),
};
DatesFilter.defaultProps = {
  anchorEl: null,
  setStates: () => {},
  states: {},
};

export default DatesFilter;
