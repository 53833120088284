import React from 'react';
import { Typography, Stack } from '@mui/material';
import { FingoLogo, LinkedinIcon, InstagramIcon, WhatsappIcon } from '../../assets';
import { mapsLink, instagramLink, linkedinLink, whatsappLink } from '../../constants/links';

const styles = {
  footerContainer: {
    bottom: 0,
    position: 'relative',
    height: '27rem',
    width: '100%',
    display: 'table',
    backgroundColor: '#F8F8F8',
    color: 'text.primary',
    padding: '4rem',
  },
  footerTitle: {
  },
  spacingGrid: {
    minHeight: '3.9rem',
    justifyContent: 'space-evenly',
  },
  href: {
    color: '#323232',
    textDecoration: 'none',
  },
  logo: {
    width: 130,
  },
  socialMedia: {
    width: 20,
    marginTop: '21%',
  },
};

function ContactDesktopFooter() {
  const contacts = [
    {
      title: 'Comercial',
      phone: '+56 9 6246 4446',
      email: 'contacto@fingo.cl',
    },
    {
      title: 'Cobranza',
      phone: '+56 9 9434 1225',
      email: 'cobranza_ratificacion@fingo.cl',
    },
  ];
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: '#F8F8F8',
        padding: '4rem 15rem',
      }}
    >
      <Typography sx={styles.footerTitle} variant="h3">Contáctanos</Typography>
      <Stack
        direction="column"
        spacing={1.5}
      >
        {contacts.map((contact) => (
          <Stack key={contact.title} direction="column" spacing={1}>
            <Typography variant="subtitle3">
              {contact.title}
            </Typography>
            <Typography variant="subtitle4">
              {contact.phone}
            </Typography>
            <Typography variant="subtitle4">
              {contact.email}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="column" justifyContent="space-between">
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle3">
            Dirección
          </Typography>
          <Typography variant="subtitle4">
            Av. Apoquindo 4660, Las Condes
          </Typography>
          <Typography variant="subtitle4">
            Piso 8
          </Typography>
          <Typography variant="subtitle4">
            <a href={mapsLink} style={styles.href} rel="noopener noreferrer" target="_blank">
              Ver en Maps
            </a>
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" justifyContent="space-between">
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle3">
            ¡Síguenos!
          </Typography>
          <Stack direction="row" spacing={1}>
            <a href={linkedinLink} rel="noopener noreferrer" target="_blank">
              <img src={LinkedinIcon} alt="logo" style={styles.socialMedia} />
            </a>
            <a href={instagramLink} rel="noopener noreferrer" target="_blank">
              <img src={InstagramIcon} alt="logo" style={styles.socialMedia} />
            </a>
            <a href={whatsappLink} rel="noopener noreferrer" target="_blank">
              <img src={WhatsappIcon} alt="logo" style={styles.socialMedia} />
            </a>
          </Stack>
        </Stack>
        <a href="/" rel="noopener noreferrer">
          <div>
            <img src={FingoLogo} alt="logo" style={styles.logo} />
          </div>
        </a>
      </Stack>
    </Stack>
  );
}
export default ContactDesktopFooter;
