import React, { useCallback, useEffect } from 'react';
import { Button, Drawer, FormControlLabel, IconButton, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Stack } from '@mui/system';
import Clear from '@mui/icons-material/Clear';
import DoubleInputDateRangePicker from '../datePickers/DoubleInputDateRangePicker';
import FilterAutocomplete from '../inputs/FilterAutocomplete';
import { cleanObjectFromUndefined, baseInvoiceFilters, dteTypeToLabel } from '../../helpers';
import useGetUser from '../../hooks/useGetUser';

const StatusFilter = (
  { openStateFilter,
    close,
    states,
    setStates,
    financingStatusOptions,
    allCompaniesOptions,
    setStatesClear,
    setStatesFull },
) => {
  const areStatesClear = useCallback((filters) => isEqual(
    cleanObjectFromUndefined(filters),
    cleanObjectFromUndefined(baseInvoiceFilters),
  ), []);
  useEffect(() => {
    if (areStatesClear(states)) {
      setStatesClear();
    } else {
      setStatesFull();
    }
  }, [states, setStatesClear, setStatesFull, areStatesClear]);
  const clear = () => {
    setStates(baseInvoiceFilters);
    close();
  };
  const user = useGetUser();
  const dateFrom = states.documentfinancestate_PaymentDate_Gte || null;
  const dateTo = states.documentfinancestate_PaymentDate_Lte || null;
  const financingOptions = [{ label: 'Disponibles', value: 'AVAILABLE' }, { label: 'No disponibles', value: 'UNAVAILABLE' }];
  const loanOptions = [{ label: 'Cedidas', value: 'Loaned' }, { label: 'No cedidas', value: 'Available' }];
  const siiStatusOptions = [
    { label: 'Con acuse de recibo', value: 'Reception acknowledged' },
    { label: 'Rechazadas', value: 'Rejected' },
    { label: 'Sin acciones en el Sii', value: 'Valid' },
  ];
  const crediNoteOptions = [
    { label: 'Con nota de crédito total', value: 'Has credit note' },
    { label: 'Con nota de crédito parcial', value: 'Partial credit note' },
    { label: 'Sin nota de crédito', value: 'Valid' },
  ];
  const documentOptions = [33, 34, 56, 61].map(
    (dteType) => ({ label: dteTypeToLabel[dteType], value: dteType }),
  );
  const integrationOptions = [
    { label: 'Rechazada', value: '1' },
    { label: 'Bloqueada en contabilidad', value: '2' },
    { label: 'Bloqueada en pagos', value: '3' },
    { label: 'Contabilizada', value: '4' },
    { label: 'Enviada para pago', value: '5' },
    { label: 'Pagada parcialmente', value: '6' },
    { label: 'Pagada completamente', value: '7' },
    { label: 'En proceso', value: '8' },
  ];
  return (
    <Drawer
      id="status-filter-drawer"
      anchor="right"
      open={openStateFilter}
      onClose={close}
    >
      <Stack
        spacing={2}
        style={{ maxWidth: '500px', width: '100vw', padding: '20px' }}
      >
        <Stack direction="row" justifyContent="space-between">
          <IconButton
            id="close-filters"
            onClick={close}
          >
            <Clear />
          </IconButton>
          <Button
            id="clean-filters"
            variant="link"
            onClick={clear}
          >
            Limpiar filtros
          </Button>
        </Stack>
        {(allCompaniesOptions && user?.selectedCompany) ? (
          <FormControlLabel
            control={(
              <Switch
                checked={states.allCompanies || false}
                onChange={(event) => setStates({
                  ...states,
                  allCompanies: event.target.checked,
                })}
              />
              )}
            label="Buscar en todas mis empresas"
          />
        ) : <></>}
        {financingStatusOptions && (
        <FilterAutocomplete
          multiple={false}
          options={financingOptions}
          states={states}
          setStates={setStates}
          filterName="availableForFinancing"
          filterTitle="Estado de financiamiento"
        />
        )}
        <FilterAutocomplete
          multiple={false}
          options={loanOptions}
          states={states}
          setStates={setStates}
          filterName="loanedStatus"
          filterTitle="Estado de cesión"
        />
        <FilterAutocomplete
          options={siiStatusOptions}
          states={states}
          setStates={setStates}
          filterName="siiStatus"
          filterTitle="Estado SII"
        />
        <FilterAutocomplete
          options={crediNoteOptions}
          states={states}
          setStates={setStates}
          filterName="creditNoteStatus"
          filterTitle="Estado nota de crédito"
        />
        <FilterAutocomplete
          options={documentOptions}
          states={states}
          setStates={setStates}
          filterName="documentType"
          filterTitle="Tipo de documento"
        />
        <FilterAutocomplete
          options={integrationOptions}
          states={states}
          setStates={setStates}
          filterName="documentFinanceStatus"
          filterTitle="Estado de integraciones"
        />
        <DoubleInputDateRangePicker
          title="Fecha de pago:"
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={
            (value) => setStates({ ...states, documentfinancestate_PaymentDate_Gte: value })
          }
          setDateTo={
            (value) => setStates({ ...states, documentfinancestate_PaymentDate_Lte: value })
          }
        />
      </Stack>
      <Button
        sx={{ alignSelf: 'start', ml: 4, mt: 2 }}
        variant="contained"
        size="small"
        onClick={close}
        id="apply-filters"
      >
        Aplicar
      </Button>
    </Drawer>
  );
};

StatusFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  openStateFilter: PropTypes.bool,
  close: PropTypes.func.isRequired,
  states: PropTypes.shape({
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    documentType: PropTypes.arrayOf(PropTypes.number),
    loanedStatus: PropTypes.string,
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
    availableForFinancing: PropTypes.string,
    documentfinancestate_PaymentDate_Gte: PropTypes.string,
    documentfinancestate_PaymentDate_Lte: PropTypes.string,
    allCompanies: PropTypes.bool,
  }).isRequired,
  setStates: PropTypes.func.isRequired,
  financingStatusOptions: PropTypes.bool,
  allCompaniesOptions: PropTypes.bool,
  setStatesClear: PropTypes.func,
  setStatesFull: PropTypes.func,
};
StatusFilter.defaultProps = {
  openStateFilter: false,
  financingStatusOptions: false,
  allCompaniesOptions: false,
  setStatesClear: () => {},
  setStatesFull: () => {},
};

export default StatusFilter;
