import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Bolt from '@mui/icons-material/Bolt';

const LightningPaymentAuthorizedFilter = ({ states, setStates }) => {
  const [triggered, setTriggered] = useState(false);
  const { filterByLightningPaymentAuthorized, ...rest } = states;
  return (
    <Tooltip title="Filtrar por Giro Rapido">
      <Bolt
        color={triggered ? 'success' : 'gray'}
        sx={{ width: 25, height: 25, cursor: 'pointer' }}
        onClick={() => {
          setStates(
            { ...rest,
              filterByLightningPaymentAuthorized: !triggered,
            },
          );
          setTriggered(!triggered);
        }}
      />
    </Tooltip>
  );
};

LightningPaymentAuthorizedFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.object.isRequired,
  setStates: PropTypes.func.isRequired,
};

export default LightningPaymentAuthorizedFilter;
