import React from 'react';
import ContactDesktopFooter from './ContactFullFooter';
import ContactMobileFooter from './ContactMobileFooter';
import { useIsMobile } from '../../hooks';

function ContactLandingFooter() {
  const isMobile = useIsMobile();
  if (isMobile) return <ContactMobileFooter />;
  return <ContactDesktopFooter />;
}

export default ContactLandingFooter;
